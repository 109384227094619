<template>
  <div>
    <div class="text-700 text-center my-3">
      <div class="text-900 font-bold text-5xl">Contact Us</div>
    </div>
    <div class="m-auto md:col-12 lg:col-7">
      <div class="surface-section px-3 py-6 lg:px-8">

        <!-- Error -->
        <div v-if="error" class="mb-5">
          <div
            class="
              flex
              align-items-start
              p-4
              bg-pink-100
              border-round border-1 border-pink-300
            "
          >
            <i class="pi pi-times-circle text-pink-900 text-2xl mr-3"></i>
            <div class="mr-3">
              <div class="text-pink-900 font-medium text-xl mb-3 line-height-1">
                Error
              </div>
              <ul class="m-0 p-0 text-pink-700 ml-3">
                <li class="p-1">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="ml-auto">
              <a
                class="
                  inline-flex
                  align-items-center
                  justify-content-center
                  ml-auto
                  border-circle
                  hover:bg-pink-50
                  no-underline
                  cursor-pointer
                  transition-colors transition-duration-150
                  p-ripple
                "
                style="width: 1.5rem; height: 1.5rem"
                ><i class="pi pi-times text-pink-900"></i
                ><span class="p-ink"></span
              ></a>
            </div>
          </div>
        </div>


        <!-- Success-->
        <div
          v-if="success === true"
          class="
            flex
            align-items-start
            p-4
            bg-green-100
            border-round border-1 border-green-300
            mb-6
          "
        >
          <i class="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
          <div class="mr-3">
            <div class="text-green-900 font-medium text-xl mb-2 line-height-1">
              Message sent
            </div>
            <p class="m-0 p-0 text-green-700 line-height-3">
              We have recieved your message and will get back to you as soon as
              possible.
            </p>
          </div>
          <div class="ml-auto">
            <a
              class="
                inline-flex
                align-items-center
                justify-content-center
                ml-auto
                border-circle
                hover:bg-green-50
                no-underline
                cursor-pointer
                transition-colors transition-duration-150
                p-ripple
              "
              style="width: 1.5rem; height: 1.5rem"
              ><span class="p-ink"></span
            ></a>
          </div>
        </div>

        <p class="line-height-3 text-600 text-2xl mb-5">
          Please fill in the form below and we will get back to you as soon as
          possible.<br />
        </p>

        <div class="grid">
          <div class="col-12 md:col-12">
            <div class="p-fluid pr-0 md:pr-6">
              <div class="field">
                <label for="name" class="font-medium"
                  >Name <small class="text-danger">* Required</small></label
                ><input
                  v-model="name"
                  class="
                    p-inputtext p-component
                    py-3
                    px-2
                    text-lg
                    py-3
                    px-2
                    text-lg
                  "
                  id="name"
                  type="text"
                />
              </div>
              <div class="field">
                <label for="email" class="font-medium"
                  >Email <small class="text-danger">* Required</small></label
                ><input
                  v-model="email"
                  class="
                    p-inputtext p-component
                    py-3
                    px-2
                    text-lg
                    py-3
                    px-2
                    text-lg
                  "
                  id="email"
                  type="text"
                />
              </div>
              <div class="field">
                <label for="company" class="font-medium">Company</label
                ><input
                  v-model="company"
                  class="
                    p-inputtext p-component
                    py-3
                    px-2
                    text-lg
                    py-3
                    px-2
                    text-lg
                  "
                  id="company"
                  type="text"
                />
              </div>
              <div class="field">
                <label for="message" class="font-medium"
                  >Message <small class="text-red-700">* Required</small></label
                ><textarea
                  v-model="message"
                  class="
                    p-inputtextarea
                    p-inputtext
                    p-component
                    p-inputtextarea-resizable
                    py-3
                    px-2
                    text-lg
                    py-3
                    px-2
                    text-lg
                  "
                  id="message"
                  rows="6"
                  style="height: calc(160px); overflow: hidden"
                ></textarea>
              </div>

              <div v-if="isLoading" class="my-5">
                <ProgressSpinner />
              </div>

              <button
                v-if="!isLoading"
                @click="submit"
                class="p-button p-component w-auto"
                type="button"
              >
                <span class="pi pi-send p-button-icon p-button-icon-left"></span
                ><span class="p-button-label">Send Message</span
                ><span class="p-ink"></span>
              </button>
            </div>
          </div>
          <!-- <div
        class="col-12 md:col-6 bg-no-repeat bg-right-bottom"
        style="background-image: url('images/blocks/contact/contact-1.png')"
      >
        <div class="text-900 text-2xl font-medium mb-6">Contact Us</div>
        <div class="text-700 line-height-3 mb-6">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>
        <a
          class="
            inline-flex
            align-items-center
            text-blue-600
            font-bold
            no-underline
            cursor-pointer
          "
          ><span class="mr-3">View Address on Google Maps</span
          ><i class="pi pi-arrow-right"></i
        ></a>
        <ul class="list-none p-0 m-0 mt-6 text-700">
          <li class="flex align-items-center mb-3">
            <i class="pi pi-phone mr-2"></i><span>+123456789</span>
          </li>
          <li class="flex align-items-center mb-3">
            <i class="pi pi-twitter mr-2"></i><span>@prime_ng</span>
          </li>
          <li class="flex align-items-center">
            <i class="pi pi-inbox mr-2"></i><span>contact@primetek.com.tr</span>
          </li>
        </ul>
      </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      name: "",
      email: "",
      company: "",
      message: "",
      error: null,
      isLoading: false,
      success: false,
    };
  },
  created() {},
  methods: {
    async submit() {
      this.error = null;
      this.isLoading = true;

      if(!this.name || !this.email || !this.message) {
        this.error = "Please fill in all the required fields.";
        this.isLoading = false;
        return;
      }

      var result = await axios.post("/api/contact-us", {
        name: this.name,
        email: this.email,
        company: this.company,
        message: this.message,
      });

      if (result.data.error) {
        this.error = result.data.error || "There was an error";
      } else {
        this.name = "";
        this.email = "";
        this.company = "";
        this.message = "";

        this.success = true;
      }

      this.isLoading = false;
    },
  },
};
</script>
